import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/BlogPost/BlogPost.tsx";
import { jsx, ThemeProvider } from 'theme-ui';
import BlogCard from '@components/BlogCard';
import InlineCode from '@components/InlineCode';
import LiveEditableCode from '@components/LiveEditableCode';
import TextLink from '@components/TextLink';
import { theme, Box, Label, Text, TextInput } from '@components/Box';
import cssPropComparisonExample from './code/css-prop-comparison.example';
import boxComparisonExample from './code/box-comparison.example';
import applicationExample from './code/application.example';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`This a follow up post exploring how the power of the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop can be used by a single component, the Box, to build up a system of common UI elements.`}</p>
    <p>{`If you aren't familiar with the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop, I recommend checking out this tidbit in my `}<a parentName="p" {...{
        "href": "/posts/sx-prop"
      }}>{`previous post`}</a>{` or from the `}<a parentName="p" {...{
        "href": "https://theme-ui.com/sx-prop/"
      }}>{`Theme UI`}</a>{` documentation.`}</p>
    <h2>{`The humble Box`}</h2>
    <p>{`The Box component is used primarily as a layout primitive to add margin, padding, and colors to content.`}</p>
    <p>{`In the last post, we implemented a minimal Box component that has the powers of the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop baked-in (i.e. without the need for the `}<a parentName="p" {...{
        "href": "https://theme-ui.com/guides/jsx-pragma"
      }}>{`JSX pragma`}</a>{`).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`/**
 * Simplified modification of the Theme UI Box
 * http://bit.ly/3pzPiAr
 */

import styled from '@emotion/styled';
import { css } from '@theme-ui/css';

// Parse the style objects passed via \`sx\` to the \`css(...)\` function
const sx = (props) => css(props.sx)(props.theme);
// Similar to \`sx\`, but used for "internal" base component styles
const base = (props) => css(props.__css)(props.theme);

// 👆 This makes adding \`sx\` style overrides easier
// for complex selectors like focus, hover, etc.

const Box = styled.div(
  {
    boxSizing: 'border-box',
    margin: 0,
    minWidth: 0,
  },
  base,
  sx,
  (props) => props.css
);

export default Box;
`}</code></pre>
    <p>{`In this version, our Box will also have a private `}<inlineCode parentName="p">{`__css`}</inlineCode>{` prop which will be used to apply base styles for our component, freeing up the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop to be used for override styles.`}</p>
    <p>{`The base styles passed via the `}<inlineCode parentName="p">{`__css`}</inlineCode>{` prop are parsed just like the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop. However, by splitting the base styles and `}<inlineCode parentName="p">{`sx`}</inlineCode>{` styles (which we can think of as inline style overrides) into two props, we let Emotion handle the complex merging of `}<a parentName="p" {...{
        "href": "https://theme-ui.com/guides/object-styles"
      }}>{`object styles`}</a>{`. This will hopefully make more sense after seeing how `}<inlineCode parentName="p">{`__css`}</inlineCode>{` is used in a bit!`}</p>
    <h2>{`A quick comparison`}</h2>
    <p>{`We can use both the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` and `}<inlineCode parentName="p">{`__css`}</inlineCode>{` props similar to how the `}<inlineCode parentName="p">{`css`}</inlineCode>{` prop is used in `}<a parentName="p" {...{
        "href": "https://emotion.sh/docs/css-prop"
      }}>{`Emotion`}</a>{`. Here is an example taken from the Emotion docs using the `}<inlineCode parentName="p">{`css`}</inlineCode>{` prop.`}</p>
    <LiveEditableCode code={cssPropComparisonExample} scope={{
      jsx
    }} mdxType="LiveEditableCode" />
    <p>{`The same result can be created using the Box component, but without the need to import our JSX pragma and with the `}<a parentName="p" {...{
        "href": "/posts/sx-prop/#the-sx-prop"
      }}>{`enhanced features`}</a>{` of the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop.`}</p>
    <LiveEditableCode code={boxComparisonExample} scope={{
      Box
    }} mdxType="LiveEditableCode" />
    <h2>{`Creating new components`}</h2>
    <p>{`By integrating the power of the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop into our Box, we can use this as a base component for building other foundational components. Let's create a few more commonly needed UI pieces.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`export const Label = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="label"
    __css={{
      fontSize: 2,
      fontWeight: 'heading',
    }}
    {...props}
  />
));

export const Text = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    __css={{
      fontSize: 1,
      fontWeight: 'body',
    }}
    {...props}
  />
));

export const TextInput = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="input"
    type="text"
    __css={{
      bg: 'background',
      border: '1px solid',
      borderColor: 'border',
      borderRadius: 'md',
      color: 'text',
      px: 3,
      py: 2,
      fontSize: 1,
      '&:focus': {
        boxShadow: ({ colors }) => \`0 0 0 2px \${colors.outline}\`,
      },
    }}
    {...props}
  />
));
`}</code></pre>
    <p>{`We use `}<inlineCode parentName="p">{`React.forwardRef`}</inlineCode>{` to automatically pass a `}<inlineCode parentName="p">{`ref`}</inlineCode>{` through to our root Box component. This allows users of these foundational components to obtain a `}<inlineCode parentName="p">{`ref`}</inlineCode>{` to the inner DOM element if needed.`}</p>
    <p>{`If you are curious, check out the React documentation for more on `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/forwarding-refs.html"
      }}>{`forwarding refs`}</a>{`.`}</p>
    <p>{`Lastly, we will need some theme values to define our design constraints. Here is a simple, sample theme object based on the Theme UI `}<a parentName="p" {...{
        "href": "https://github.com/system-ui/theme-ui/tree/develop/packages/preset-base/src/index.ts"
      }}>{`Base Preset`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// modified example base theme from @theme-ui/presets

export const theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 700,
  },
  colors: {
    text: 'var(--colors-text)',
    background: 'var(--colors-background)',
    primary: 'var(--colors-primary)',
    secondary: 'var(--colors-secondary)',
    muted: 'var(--colors-gray600)',
    border: 'var(--colors-gray400)',
    outline: 'var(--colors-gray300)',
  },
  radii: {
    none: '0',
    sm: '0.125rem',
    md: '0.375rem',
    lg: '0.5rem',
    full: '9999px',
  },
};
`}</code></pre>
    <p>{`Just a quick note on the color values used. I'm reusing some already defined `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties"
      }}>{`CSS custom properties`}</a>{` that are being used for this site, e.g. `}<inlineCode parentName="p">{`var(--colors-text)`}</inlineCode>{`. This just makes the next example look nice for both light and dark themes.`}</p>
    <p>{`Theme UI does have a `}<a parentName="p" {...{
        "href": "https://theme-ui.com/theming#color-modes"
      }}>{`color modes`}</a>{` feature which you can also leverage if your application needs to support multiple modes.`}</p>
    <h2>{`Putting them to use`}</h2>
    <p>{`The above Box-based components all have the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop baked-in. When necessary, we can sprinkle in any style customizations leveraging the design constraints from our theme.`}</p>
    <p>{`Now that we have some bits of UI, let's compose a name input for our application.`}</p>
    <LiveEditableCode code={applicationExample} scope={{
      ThemeProvider,
      theme,
      Box,
      Label,
      Text,
      TextInput
    }} mdxType="LiveEditableCode" />
    <h2>{`Wrapping up`}</h2>
    <p>{`In summary, the Box is a versatile foundation component that can be using as a building-block to create other components. Adding the flexible styling feature of the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop to the Box shares this capability to all other components created from Box. This creates a simple, consistent styling pattern across components that leverages the theme to promote styling with defined design constraints.`}</p>
    <BlogCard mdxType="BlogCard">
  <strong>Note:</strong> This technique is mostly experimental and an
  exploration of the component styling pattern used by Theme UI. Applying this
  pattern at scale for a large component library would need some added
  refinement and probably best to do in TypeScript{' '}
  <span role="img" aria-label="heart">
    💙
  </span>
    </BlogCard>
    <p>{`If this was interesting, I encourage you to check out the Theme UI `}<a parentName="p" {...{
        "href": "https://github.com/system-ui/theme-ui"
      }}>{`source code`}</a>{` for a more detailed look:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/system-ui/theme-ui/blob/develop/packages/components/src/Box.js"
        }}>{`Theme UI Box`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/system-ui/theme-ui/blob/develop/packages/components/README.md"
        }}>{`@theme-ui/components`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/system-ui/theme-ui/blob/develop/packages/css/src/index.ts#L298"
        }}>{`@theme-ui/css`}</a></li>
    </ul>
    <p>{`Here is a full example in `}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/building-from-the-box-wqpdk"
      }}>{`CodeSandbox`}</a>{` of the above all pieced together.`}</p>
    <iframe src="https://codesandbox.io/embed/building-from-the-box-wqpdk?fontsize=14&hidenavigation=1&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="building-from-the-box" allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      